import { useCallback, useEffect, useMemo, useRef } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  FormBase,
  FormModal,
  type IRole,
  Roles,
  SelectData,
  clientAndEmployeeWithLevel,
  rolesWithLevel,
  rolesWithoutAdminWithLevel,
  translateUserRoleInRole,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { COMPANY_ID } from '../../../../constants/Env';
import { type ICompanyDTO } from '../../../../services/companies';
import { type UsersCreateSchema, userCreateSchema } from '../../userSchema';

interface IAddTeammatesProps {
  onAccept: (data: UsersCreateSchema) => void;
  onDecline: () => void;
  open: boolean;
  loading: boolean;
  success?: boolean;
  company: ICompanyDTO;
}

const getCurrentRole = (userRoles: string[]): IRole | null => {
  let currentRole = null;
  for (const value of userRoles.values()) {
    const userRoleAux = translateUserRoleInRole[value] ?? null;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!userRoleAux) {
      continue;
    }

    if (!currentRole) {
      currentRole = userRoleAux;
    } else if (currentRole.level < userRoleAux.level) {
      currentRole = userRoleAux;
    }
  }

  return currentRole;
};

const MAIN_CLIENT_ID_NUM = Number(COMPANY_ID);

export const ModalAddUsers = ({
  onAccept,
  onDecline,
  open,
  loading = false,
  success = false,
  company,
}: IAddTeammatesProps): JSX.Element => {
  const isSelectOpen = useRef(new Set());
  const { user } = useAuth();
  const { companyId = '' } = useParams();
  const selectData = useRef(null);

  const createUsers = useForm<UsersCreateSchema>({
    resolver: zodResolver(userCreateSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      roles: Roles.EMPLOYEE,
      clientId: Number(companyId),
      phone: '',
    },
  });

  const {
    reset,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = createUsers;

  const values = watch();

  const handleRoleInviteSelectChange = (value: string) => {
    setValue('roles', value);
  };

  const resetForm = useCallback(() => {
    reset();
  }, [reset]);

  const handleDeclineAddTeammates = useCallback(() => {
    if (!isSelectOpen.current.size) {
      resetForm();
      onDecline?.();
    }
  }, [onDecline, resetForm]);

  useEffect(() => {
    if (success) {
      resetForm();
    }
  }, [success]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const onSubmit: SubmitHandler<UsersCreateSchema> = (
    data: UsersCreateSchema
  ) => {
    onAccept?.(data);
  };

  const currentRole = useMemo(() => {
    const userRoles = user?.roles ?? [];
    if (!userRoles.length) {
      return null;
    }

    return getCurrentRole(userRoles);
  }, [user]);

  const rolesAux = useMemo(() => {
    if (currentRole !== null) {
      let roleAuxToFilter;

      if (Number(values.clientId) !== MAIN_CLIENT_ID_NUM) {
        roleAuxToFilter =
          company?.isAgency === true
            ? rolesWithoutAdminWithLevel
            : clientAndEmployeeWithLevel;
      } else {
        roleAuxToFilter = rolesWithLevel;
      }

      return roleAuxToFilter.filter(
        ({ level }) => level <= (currentRole.level ?? 0)
      );
    } else {
      return [];
    }
  }, [currentRole, companyId, company, values.clientId]);

  return !open ? (
    <></>
  ) : (
    <FormBase.Provider {...createUsers}>
      <FormModal
        acceptText="Add User"
        declineText="Cancel"
        open={open}
        dataCy="add-userForm"
        mainText="New User"
        onOpenChange={handleDeclineAddTeammates}
        onDecline={handleDeclineAddTeammates}
        onAccept={handleSubmit(onSubmit)}
        loading={loading}
        formId="add-clientForm"
      >
        <FormBase.Content>
          <FormBase.InputContent
            filled={!!values?.firstName}
            inputRef="firstNameEdit"
            label="First Name"
            errorMessage={errors.firstName?.message}
            dataCy="label-First Name"
          >
            <FormBase.InputText
              dataCy="firstNameEdit-input"
              id="firstNameEdit"
              name="firstName"
              type="text"
              autoComplete="off"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.lastName}
            inputRef="lastNameEdit"
            label="Last Name"
            errorMessage={errors.lastName?.message}
            dataCy="label-Last Name"
          >
            <FormBase.InputText
              dataCy="lastNameEdit-input"
              id="lastNameEdit"
              name="lastName"
              type="text"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.email}
            inputRef="emailInvite"
            label="Email"
            errorMessage={errors.email?.message}
            dataCy="label-Email"
          >
            <FormBase.InputText
              dataCy="emailInvite-input"
              id="emailInvite"
              name="email"
              type="text"
              maxLength={40}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.phone}
            inputRef="phoneInvite"
            label="Phone"
            errorMessage={errors.phone?.message}
            dataCy="label-Phone"
          >
            <FormBase.InputText
              dataCy="phoneInvite-input"
              id="phoneInvite"
              name="phone"
              type="text"
              maxLength={15}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values.roles}
            inputRef="select-role"
            label="User Type"
            errorMessage={errors.roles?.message}
            dataCy="label-Permission"
            value={values.roles}
            isSelectData
          >
            <SelectData
              data={rolesAux}
              value={values.roles}
              dataCy="button-select-role-field"
              onValueChange={handleRoleInviteSelectChange}
              zIndex={2}
              ref={selectData}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'role');
              }}
              placeholder="User Type"
            />
          </FormBase.InputContent>
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};
